<template>
    <div class="wrap">
        <div class="header">
            <div class="cnt">
                <div class="logo" @click="$router.push({path:'/'})">
                    <img src="@/assets/images/capital/bigLogo.png" alt="">
                    <b>知识产权鉴定</b>
                </div>
            </div>
        </div>
        <div class="bannerWrap">
            <div class="jd_banner">
                <div class="banner_cnt">
                    <span @click="dockModal=true">立即申请鉴定</span>
                </div>
            </div>
        </div>
        <div class="introduce">
            <div class="jd_title">
                鉴定专委会介绍
            </div>
            <div class="introduce_bg">
                <div class="introduce_cnt">
                    <div class="font">
                        <p>
                            首都知识产权服务业协会知识产权鉴定专家委员会（以下简称“首都知识产权服务业协会鉴定专委会”)，依托首都知识产权服务行业优质专家资源开展知识产权鉴定工作。
                        </p>
                        <p>
                            首都知识产权服务业协会鉴定专委会前身为北京智慧知识产权司法鉴定中心，是国内最早开展知识产权司法鉴定的机构之一，于2003年12月经北京市司法局批准成立，2004年入选最高人民法院和北京市高级人民法院“人民法院对外委托司法鉴定机构名册”。
                        </p>
                        <p>
                            2020年底，根据主管部门的相关要求，北京智慧知识产权司法鉴定中心鉴定业务主体变更为首都知识产权服务业协会，并通过北京市高级人民法院审核，列入人民法院诉讼资产网(https://www.rmfysszc.gov.cn/，最高人民法院司法行政装备管理局主管)公告的人民法院对外委托专业机构名册。
                        </p>
                        <p>
                            2022年底，首都知识产权服务业协会通过了知识产权鉴定管理体系认证，成为首批通过知识产权鉴定管理体系认证的鉴定机构之一。
                        </p>
                        <p>
                            2023年初，首都知识产权服务业协会被国家知识产权局纳入知识产权鉴定机构名录库，成为首批入选国家知识产权局知识产权鉴定机构名录库的四家鉴定机构之一。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="business">
            <div class="jd_title">
                业务范围
            </div>
            <div class="business_cnt">
                <ul>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus1.png" alt="">
                            <p>商业秘密</p>
                        </div>
                        <div class="hover">
                            <h4>商业秘密</h4>
                            <p>
                                <b></b>
                                委托人主张的技术信息或经营信息是否不为公众所知悉；
                            </p>
                            <p>
                                <b></b>
                                涉嫌侵权方的信息与权利人主张的不为公众所知悉的信息是否相同或实质相同；
                            </p>
                            <p>
                                <b></b>
                                其他涉及商业秘密的相关鉴定。
                            </p>
                            <span @click="showDing('商业秘密')">立即鉴定</span>
                        </div>
                    </li>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus2.png" alt="">
                            <p>专利</p>
                        </div>
                        <div class="hover">
                            <h4>专利</h4>
                            <p>
                                <b></b>
                                涉嫌侵权产品或方法的技术特征与权利人主张的专利的技术特征是否相同或等同；
                            </p>
                            <p>
                                <b></b>
                                涉嫌侵权产品的外观设计与权利人主张的外观设计专利是否相同或相近似；
                            </p>
                            <p>
                                <b></b>
                                专利无效程序中的相关技术鉴定；
                            </p>
                            <p>
                                <b></b>
                                其他涉及专利的相关鉴定。
                            </p>
                            <span @click="showDing('专利')">立即鉴定</span>
                        </div>
                    </li>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus3.png" alt="">
                            <p>著作权/版权</p>
                        </div>
                        <div class="hover">
                            <h4>著作权/版权</h4>
                            <p>
                                <b></b>
                                涉嫌侵权作品与权利人主张的作品是否相同或实质相似；
                            </p>
                            <p>
                                <b></b>
                                涉嫌侵权软件内容与权利人主张的软件内容是否相同或实质相似；
                            </p>
                            <p>
                                <b></b>
                                其他涉及著作权的相关鉴定。
                            </p>
                            <span @click="showDing('著作权/版权')">立即鉴定</span>
                        </div>
                    </li>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus4.png" alt="">
                            <p>商标</p>
                        </div>
                        <div class="hover">
                            <h4>商标</h4>
                            <p>
                                <b></b>
                                涉嫌侵权商品（或服务）与权利人注册商标核定使用的商品（或服务）是否属于同种或类似商品（或服务）；
                            </p>
                            <p>
                                <b></b>
                                涉嫌侵权商标标识与权利人主张的注册商标是否相同或相近似。
                            </p>
                            <span @click="showDing('商标')">立即鉴定</span>
                        </div>
                    </li>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus5.png" alt="">
                            <p>技术合同类</p>
                        </div>
                        <div class="hover">
                            <h4>技术合同类</h4>
                            <p>
                                <b></b>
                                技术开发、技术转让等合同的技术成果是否达到合同约定的技术标准或存在重大技术缺陷；
                            </p>
                            <p>
                                <b></b>
                                转让方履行技术转让合同所交付的技术文件资料是否符合合同约定的标准；
                            </p>
                            <p>
                                <b></b>
                                履行合同所做的技术指导是否符合合同约定的技术服务内容；
                            </p>
                            <p>
                                <b></b>
                                其他涉及技术合同的相关鉴定。
                            </p>
                            <span @click="showDing('技术合同类')">立即鉴定</span>
                        </div>
                    </li>
                    <li>
                        <div class="default">
                            <img src="@/assets/images/identification/bus6.png" alt="">
                            <p>其他</p>
                        </div>
                        <div class="hover">
                            <h4>其他</h4>
                            <p>
                                <b></b>
                                对涉嫌侵权物与权利人主张的权利范围是否相同或者实质相似/等同进行鉴定，如集成电路布图设计、植物新品种、商品包装、商品装潢等。
                            </p>
                            <span @click="showDing('其他')">立即鉴定</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flow">
            <div class="jd_title">
                鉴定流程
            </div>
            <div class="flow_cnt">
                <img src="@/assets/images/identification/flow.png" alt="">
            </div>
        </div>
        <div class="customer"></div>
        <div class="honor">
            <div class="jd_title">
                荣誉资质
            </div>
            <ul>
                <li>
                    <img src="@/assets/images/identification/honor1.png" alt="">
                </li>
                <li>
                    <img src="@/assets/images/identification/honor2.png" alt="">
                </li>
                <li>
                    <img src="@/assets/images/identification/honor3.png" alt="">
                </li>
                <li>
                    <img src="@/assets/images/identification/honor4.png" alt="">
                </li>
            </ul>
        </div>
        <div class="contact">
            <div class="jd_title">
                联系我们
            </div>
            <div class="contact_cnt">
                <div class="left">
                    <el-amap vid="amap" class="map" :center="center" :plugin="plugins" :zoom="15">
                        <el-amap-marker vid="component-marker" :position="position" />
                    </el-amap>
                </div>
                <div class="right">
                    <ul>
                        <li>
                            <b></b>
                            <p>服务热线：010-66139027</p>
                        </li>
                        <li>
                            <b></b>
                            <p>服务邮箱：ipfs@capitalip.org </p>
                        </li>
                        <li>
                            <b></b>
                            <p>地址：北京市海淀区海淀南路甲21号中关村知识产权大厦A座三层</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="footer">
            <div class="cnt">
                <dl class="left">
                    <dt @click="$router.push({path:'/'})">
                        <img src="@/assets/images/capital/bigLogo.png" alt="">
                    </dt>
                    <dd>
                        <h3>{{ $t('footer.webName') }}</h3>
                        <h5 v-if="$i18n.locale==='zh'">Capital Intellectual Property Services Association</h5>
                        <div class="info">
                            <p v-html="$t('footer.address')"></p> 
                            <p v-html="$t('footer.email')"></p>
                            <p>
                                <span>{{$t('footer.ICP')}}</span> 
                                <span>{{ $t('footer.record') }}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="right">
                    <div>
                        <h4>{{ $t('footer.support') }}</h4>
                        <p @click="gotoIndex" class="gotoIndexClass">
                            <img src="@/assets/images/capital/tsaLogo.png" width="235" alt="" v-if="$i18n.locale==='zh'">
                            <img src="@/assets/images/capital/logoEn.png" width="235" alt="" v-else>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Ding :show="dockModal" :activeTitle="activeTitle"  @onClose="dockModal=false" />
    </div>
</template>
  
<script>
import Ding from "@/components/Ding";
export default {
    data() {
        return {
            position: [116.309221,39.976594], // 定位
            center: [116.309221,39.976594], // 地图初始化视图中心点
            plugins: ["AMap.Scale", "AMap.ToolBar"],
            dockModal:false,
            activeTitle:'',
        };
    },
    components: {Ding},

    created() {},

    mounted() {},
    methods: {
        gotoIndex () {
            window.open(process.env.VUE_APP_TSA)
        },
        showDing(title){
            if(title){
                this.activeTitle=title;
            }
            this.dockModal=true;
        }
    },
};
</script>
<style src="@/assets/css/home.less" lang="less" scoped></style>
  