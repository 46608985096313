<template>
    <div class="modal">
        <el-dialog
            title="提交接入申请"
            :visible.sync="visibleThird"
            :close-on-click-modal="false"
            :before-close="closeThird"
            class="myDialog"
        >
            <el-row class="form2">
                <p class="centerP">很快，我们的专业顾问会与您联系。</p>
                <el-col :span="24">
                    <el-form
                        :rules="rules"
                        ref="form2"
                        :model="form2"
                        class="subform"
                    >
                        <el-form-item label=" " label-width="20px" prop="authenticationType">
                            <el-select v-model="form2.authenticationType" placeholder="请选择鉴定类型" style="width: 100%;">
                                <el-option
                                    v-for="item in options"
                                    :key="item.label"
                                    :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="name">
                            <el-input placeholder="请输入联系人姓名" v-model="form2.name">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="phone">
                            <el-input placeholder="请输入联系人手机号" v-model="form2.phone">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="verify2">
                            <el-input
                                placeholder="请输入联系人手机号"
                                v-model="form2.verify2"
                                v-show="false"
                            >
                            </el-input>
                            <Verification @verification="verification" ref="Verification"/>
                        </el-form-item>
                        <div class="yzm-item">
                            <el-form-item
                                label=" "
                                label-width="20px"
                                prop="captchaCode"
                            >
                                <el-input
                                    v-model="form2.captchaCode"
                                    placeholder="短信验证码"
                                ></el-input>
                            </el-form-item>
                            <span
                                :disabled="disabled"
                                @click="getVer"
                                >{{ yzmtext }}</span
                            >
                        </div>

                        <el-form-item label=" " label-width="20px" prop="description">
                            <el-input type="textarea" @input="fontNumber" placeholder="请输入需求描述（不多于200字）" :maxLength="200" :rows="4" v-model="form2.description"/>
                            <span style="float:right;margin-bottom:-34px">{{ fontNum }}/200</span>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeThird('cancel')">取消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="applyDialogOkThird">提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Verification from "@/components/Verification/dockingV";
var interval=null;
import {sendDingTalk,sendSmsCode} from "@/api/service";

export default {
    data() {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"));
            } else {
                if (!reg.test(value)) {
                    callback(new Error("请输入正确手机号"));
                } else {
                    callback();
                }
            }
        };
        let verifyPass = (rule, value, callback) => {
            if (!this.verify) {
                callback(new Error("请滑块验证"));
            } else {
                callback();
            }
        };
        return {
            visibleThird:false,
            form2: {
                authenticationType:'',
                phone: "",
                verify2: "",
                captchaCode: "",
                description: "",
                name: ""
            },
            rules: {
                authenticationType:[
                    {
                        required: true,
                        message: "请选择鉴定类型",
                        trigger: "change"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validatePass
                    }
                ],
                description: [
                    {
                        required: true,
                        message: "请输入需求描述",
                        trigger: "blur"
                    }
                ],
                captchaCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "change"
                    }
                ],
                verify2: [
                    {
                        required: true,
                        trigger: "change",
                        validator: verifyPass
                    }
                ]
            },
            yzmtext: "获取验证码",
            verify:false,
            currentTime: 60,
            disabled: false,
            fontNum:0,
            btnLoading:false,
            options:[
                {
                    label:'商业秘密'
                },
                {
                    label:'专利'
                },
                {
                    label:'著作权/版权'
                },
                {
                    label:'商标'
                },
                {
                    label:'技术合同类'
                },
                {
                    label:'其他'
                }
            ]
        };
    },

    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
        activeTitle:{
            type: String,
            default: () => '',
        },
    },

    components: {Verification},

    computed: {},

    watch: {
        show(val){
            this.visibleThird=val;
        },
        activeTitle(val){
            this.form2.authenticationType=val;
        },
    },

    created() {},

    mounted() {},

    methods: {
        fontNumber(e) {
            this.fontNum = e.length;
        },
        applyDialogOkThird(e) {
            e.preventDefault();
            let that = this;
            this.$refs.form2.validate(async valid => {
                if (valid) {
                    this.btnLoading = true;
                    let {authenticationType,phone, name, captchaCode, description} = that.form2;
                    let obj={
                        authenticationType,
                        phone,
                        name,
                        code:captchaCode,
                        description,
                    }
                    try{
                        let res = await sendDingTalk(obj);
                        if (res.code === 200) {
                            that.form2 = {
                                name: "",
                                phone: "",
                                authenticationType: "",
                                verify2: "",
                                captchaCode: "",
                                description: '',
                            };
                            that.$message.success("申请成功！");
                            that.$refs.Verification.resetNvc();
                            that.$refs["form2"].resetFields();
                            that.resetForm();
                            this.$emit('onClose');
                        } else {
                            this.$message.warning(res.data.message);
                        }
                    }catch (error) {
                        this.$message.warning(error.data.message);
                    }
                    that.btnLoading = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        backSec() {
            this.visibleThird = false;
            this.visibleSec = true;
            this.$refs.Verification.resetNvc();
            clearInterval(interval);
            this.$refs["form2"].resetFields();
        },
        async verification(data) {
            let that = this;
            if (data) {
                this.verify=data.verify;
                this.$refs["form2"].clearValidate(["verify2"]);
                let { phone } = that.form2;
                if (!phone) {
                    that.$message.warning("手机号不能为空");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                let reg = /^1[3456789]\d{9}$/;
                if (!reg.test(phone)) {
                    that.$message.warning("手机号格式不正确");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                that.getCode();
                try {
                    let res = await sendSmsCode({
                        nvc: data.nvc,
                        phone: that.form2.phone
                    });
                    that.$message.success(res.message);
                    that.verify = true;
                    that.form2.verify2 = "213";
                    that.$refs["form2"].clearValidate(["verify2"]);
                } catch (error) {
                    that.$message.warning(error.message);
                    that.$refs.Verification.resetNvc();
                    clearInterval(interval);
                    that.yzmtext = "获取验证码";
                    that.disabled = false;
                    that.verify = false;
                }
            }
        },
        getVer() {
            let {phone} = this.form2;
            let that = this;
            if (!phone) {
                that.$message.warning("手机号不能为空");
                return false;
            }
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(phone)) {
                that.$message.warning("手机号格式不正确");
                return false;
            }
            if (!this.verify) {
                that.$message.warning("请先进行滑块验证");
                return false;
            }
            setTimeout(()=>{
                that.$refs.Verification.alicode();
            },0)
            
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            that.disabled = true;
            interval = setInterval(function() {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)重新获取";
                if (currentTime <= 0) {
                    clearInterval(interval);
                    that.currentTime = 60;
                    that.yzmtext = "获取验证码";
                    that.disabled = false;
                }
            }, 1000);
        },
        closeThird(done){
            this.$refs.Verification.resetNvc();
            this.$refs["form2"].resetFields();
            this.resetForm();
            this.$emit('onClose');
            if(done!=='cancel'){
                done&&done();
            }
        },
        //置空钉钉推送相关字段
        resetForm(){
            this.visibleThird = false;
            this.disabled = false;
            this.yzmtext = "获取验证码";
            this.currentTime=60;
            this.verify = false;
            this.checkIndex = "";
            clearInterval(interval);
            this.form2.enterpriseName = "";
            this.form2.captchaCode = "";
        },
    },
};
</script>
<style lang='less' scoped>
.yzm-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    .el-form-item {
        width: 330px;
        margin-bottom: 0px;
    }
    span {
        width: 148px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: #fff;
        color: #3f7df7;
        cursor: pointer;
        border: 1px solid #3f7df7;
        font-size: 14px;
        border-radius: 3px;
        &:hover {
            background: #3f7df7;
            color: #fff;
        }
        &[disabled] {
            color: #fff;
            background: #bcbcbc;
            pointer-events: none;
            cursor: inherit;
            border-color: #bcbcbc;
        }
    }
}
.myDialog{
    :deep(.el-dialog){
        max-width:530px;
    }
    :deep(.el-dialog__header){
        padding:16px 24px;
        border-bottom: 1px solid #e8e8e8;
        .el-dialog__title{
            font-size: 16px;
            line-height: 22px;
            color:#111;
        }
    }
    :deep(.el-dialog__footer){
        padding: 10px 16px;
        border-top: 1px solid #e8e8e8;
    }
    :deep(.el-dialog__body){
        padding: 24px;
        .btnInfo {
            margin-bottom: 20px;
            width: 98px;
            height: 36px;
            border: 1px solid #d0d0d0;
            border-radius: 18px;
            line-height: 36px;
            padding: 0;
            margin-left: 0;
            margin-right: 15px;
        }
        .checked {
            background: #e8efff;
            border: 1px solid #3758ff;
            border-radius: 18px;
            color: #3758ff;
        }
        .centerP {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-bottom: 30px;
            text-align: center;
        }
        .btnCode {
            padding: 9px 24px;
            margin-right: 0px;
            font-size: 12px;
        }
        .el-input__inner{
            height: 36px;
            line-height: 36px;
        }
    }
}

@media (max-width: 750px){
    .myDialog{
        :deep(.el-dialog){
            width: 96%;
        }
    }
}
</style>